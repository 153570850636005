<template>
  <div class="">
    <div class="text-end">
      <button type="button" class="btn btn-secondary mb-3" @click.prevent="goToCreate('Admin')">新增使用者</button>
    </div>
    <div class="mb-5">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          placeholder: '搜尋',
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'userName', type: 'asc'}
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          perPageDropdownEnabled: true,
          perPageDropdown: [10, 20],
          nextLabel: '下一頁',
          prevLabel: '上一頁',
          rowsPerPageLabel: 'Rows per page',
          allLabel: '全部',
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'btn'">
            <button type="button" class="btn btn-main btn-sm me-1 mb-1 mb-md-0" style="white-space:nowrap;" @click.prevent="goAdminUser(props.row.id)">管理</button>

            <!-- <p>{{ props.row.id }}</p> -->
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__Users',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      page: 1,
      data: [],
      noData: false,
      tempCourse: {},
      is_enabled: true,
      createModalRole: '系統管理員',
      fileUploading: '',
      newUser: {
        userName: '',
        avatar: '',
        email: '',
        account: '',
        role: '',
      },
      columns: [
        {
          label: '使用者名稱',
          field: 'userName',
        },
        {
          label: '帳號',
          field: 'account',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: '身份',
          field: 'role',
        },
        {
          label: '編輯',
          field: 'btn',
        },
      ],
      rows: [
        {
          id: 0, userName: 'test', account: 'ddtest', email: 'al@gmail.com', role: '課程教師',
        },
        {
          id: 1, userName: 'test', account: 'cctest', email: 'al@gmail.com', role: '課程教師',
        },
      ],
      metaTitle: '使用者列表 / 使用者 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "使用者列表 / 使用者 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '使用者列表 / 使用者 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Admin/Member/Management`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data.data.userList);
        this.updateLoading(false);
        this.data = [...res.data.data.userList];
        this.data.forEach((item) => {
          if (item.role == 'Member') item.role = '一般會員';
          if (item.role == 'Admin') item.role = '系統管理員';
          if (item.role == 'Teacher') item.role = '課程教師';
        });
        this.rows = [...this.data];
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }
      });
    },
    switchPage(num) {
      this.page += num;
      if (this.page < 1) {
        this.page = 1;
      }
      if (this.noData) {

      }
      this.getData();
    },
    openDeleteModal(item) {

    },
    handleModalRole(str) {
      this.createModalRole = str;
      this.fileUploading = '';
    },
    save() {
      // connect to api
      this.newUser = {
        userName: '',
        avatar: '',
        email: '',
        account: '',
        role: '',
      };
      this.$refs.img.src = '';
      this.newUser.avatar = '';
      this.$refs.files.value = null;
      this.$refs.img.src = '';
    },
    openDeleteModal() {

    },
    goToCreate(role) {
      this.$router.push(`/admin/r/user/${role}`);
    },
    goAdminUser(id) {
      this.$router.push(`/admin/user/${id}`);
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .pageNum {
    cursor: pointer;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
      color: gray;
      cursor: default;
    }
  }
</style>
