var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"text-end"},[_c('button',{staticClass:"btn btn-secondary mb-3",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.goToCreate('Admin')}}},[_vm._v("新增使用者")])]),_c('div',{staticClass:"mb-5"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
        enabled: true,
        placeholder: '搜尋',
      },"sort-options":{
        enabled: true,
        initialSortBy: {field: 'userName', type: 'asc'}
      },"pagination-options":{
        enabled: true,
        mode: 'records',
        perPage: 10,
        perPageDropdownEnabled: true,
        perPageDropdown: [10, 20],
        nextLabel: '下一頁',
        prevLabel: '上一頁',
        rowsPerPageLabel: 'Rows per page',
        allLabel: '全部',
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'btn')?_c('span',[_c('button',{staticClass:"btn btn-main btn-sm me-1 mb-1 mb-md-0",staticStyle:{"white-space":"nowrap"},attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.goAdminUser(props.row.id)}}},[_vm._v("管理")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('Meta',{attrs:{"title":_vm.metaTitle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }